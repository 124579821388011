import {AbstractControl, ValidationErrors} from '@angular/forms';

export const NUMERIC_RANGE_KEY = 'numeric_range';
export const NUMERIC_RANGE_DATE_KEY = 'numeric_date_range';

export const numericRangeValidator =
  (min: number | null, max: number | null, isInvalidEntryError = false) =>
  (control: AbstractControl): ValidationErrors | null => {
    const {value} = control;
    if (!/^-?[0-9]+$/.test(value)) {
      return null;
    }

    const numValue = +value;

    const isValid = (min === null || numValue >= min) && (max === null || numValue <= max);

    return !isValid
      ? {[isInvalidEntryError ? NUMERIC_RANGE_DATE_KEY : NUMERIC_RANGE_KEY]: {value: control.value, min, max}}
      : null;
  };
